import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import HeroArea from "@containers/hero/layout-04";
import ServicesArea from "@containers/service/layout-01";
import PortfolioArea from "@containers/portfolio/layout-01";
import ResumeArea from "@containers/resume/layout-01";
import TestimonialArea from "@containers/testimonial/layout-01";
import ClientArea from "@containers/client/layout-01";
import PricingArea from "@containers/pricing/layout-01";
import BlogArea from "@containers/blog/layout-01";
import ContactArea from "@containers/contact/layout-01";
import EducationArea from "@containers/education/layout-01";
import SkillArea from "@containers/skill/layout-01";
import ExperienceArea from "@containers/experience/layout-01";
import InterviewArea from "@containers/interview/layout-01";
import "./Styles.css";
import Sticky from "@ui/sticky";

const IndexPolititcianPage = ({ data }) => {
    const content = normalizedData(data?.homePage?.content || []);

    return (
        <Layout pageTitle="Home Politician">
            <Header
                data={{
                    ...data.header,
                    ...data.navigation,
                    socials: data.site.siteMetadata.socials,
                }}
            />
        

            <div>
            <div>
                <main className="main-page-wrapper">
                    <div className="rn-slider-area">
                        <div className="container">
                            <div className="row row--30 pt--100 pt_sm--50">
                                <div className="col-lg-6">
                                    <div className="d-flex flex-wrap align-content-start h-100">
                                        <Sticky
                                            className="sticky-top-slider"
                                            top="200px"
                                        >
                                            <div className="banner-details-wrapper-sticky slide">
                                                <div className="thumbnail">
                                                    <img
                                                        src={
                                                            "../static/anuj-p-1.png"
                                                        }
                                                    />
                                                </div>
                                                <div className="banner-title-area pt--30">
                                                    <h1
                                                        className="title"
                                                        style={{
                                                            color: "#000",
                                                        }}
                                                    >
                                                        Hi, I’m{" "}
                                                        <span>Anuj</span>
                                                        <br />
                                                        {/*<span className="span" style={{ color: '#000' }}> Software Engineer.</span>*/}
                                                    </h1>
                                                    <p className="disc">
                                                        Versatile, reputable,
                                                        and Filmfare
                                                        Award-winning Indian
                                                        playback singer with
                                                        over 30 films, I sing in
                                                        Hindi, English and
                                                        Regional languages. I
                                                        perform in corporate and
                                                        social gatherings,
                                                        spicing up events with
                                                        positive and energetic
                                                        vibes. 
                                                    </p>
                                                </div>
                                                <div className="button-group text-center text-md-left pt--60 pt_md--40 pt_sm--40">
                                                    <a
                                                        className="rn-btn"
                                                        href="#contacts"
                                                    >
                                                        <span>
                                                            <span>
                                                                Download My CV
                                                            </span>
                                                        </span>
                                                    </a>
                                                    <a
                                                        className="rn-btn"
                                                        href="#contacts"
                                                    >
                                                        <span>
                                                            <span>Contact</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </Sticky>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="sticky-home-wrapper">
                                        <div className="rn-about-area section-height">
                                            <div className="inner">
                                                <h5 className="title">
                                                    SINGER
                                                </h5>
                                                <p className="about-disc">
                                                    As a voice with a mission to
                                                    bound generations of Indians
                                                    and dominate the Indian film
                                                    music industry, I
                                                    continually invest in my
                                                    personal development to
                                                    ensure my versatility. Over
                                                    the years, I have written
                                                    and recorded many playback
                                                    songs for several
                                                    high-performing films in the
                                                    cinema and performed in many
                                                    high-profile corporate and
                                                    social events.
                                                </p>
                                                <p className="about-disc">
                                                    Despite not hailing from a
                                                    Telugu speaking background,
                                                    I debuted as a playback
                                                    singer for SS Rajamouli’s
                                                    magnum opus – Magadheera.
                                                    Under the guidance of the
                                                    legendary music director, Mr
                                                    M.M. Keeravaani, I recorded
                                                    my debut song, Panchadara
                                                    Bomma, which became an
                                                    instant super hit just a few
                                                    months after releasing the
                                                    film. The song brought home
                                                    the Filmfare Award for the
                                                    Best Playback Singer.
                                                </p>
                                                <p className="about-disc">
                                                    I record songs for films in
                                                    genres ranging from love to
                                                    hip hop, classic to folk. If
                                                    you need a Bollywood
                                                    playback singer to compose &
                                                    record a unique song for
                                                    your film or perform on your
                                                    special occasion, please
                                                    feel free to contact me.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sticky-home-wrapper">
                                        <div className="rn-about-area section-height">
                                            <div className="inner">
                                                <h5 className="title">
                                                    Want to hear me sing? Check
                                                    the videos below 
                                                </h5>

                                                <div className="flex-50-m">
                                                    <div className="flex-50-i">
                                                        <iframe
                                                            width="100%"
                                                            height="215"
                                                            src="https://www.youtube.com/embed/oJrzTCU1cO8"
                                                            title="YouTube video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        ></iframe>
                                                    </div>

                                                    <div className="flex-50-i">
                                                        <iframe
                                                            width="100%"
                                                            height="215"
                                                            src="https://www.youtube.com/embed/jTT3RFMCWV4"
                                                            title="YouTube video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        ></iframe>
                                                    </div>
                                                    <div className="flex-50-i">
                                                        <iframe
                                                            width="100%"
                                                            height="215"
                                                            src="https://www.youtube.com/embed/qTE453yc1rc"
                                                            title="YouTube video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        ></iframe>
                                                    </div>
                                                    <div className="flex-50-i">
                                                        <iframe
                                                            width="100%"
                                                            height="215"
                                                            src="https://www.youtube.com/embed/BjHcqVJETdk"
                                                            title="YouTube video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        ></iframe>
                                                    </div>
                                                    <div className="flex-50-i">
                                                        <iframe
                                                            width="100%"
                                                            height="215"
                                                            src="https://www.youtube.com/embed/NLRnshDleSc"
                                                            title="YouTube video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        ></iframe>
                                                    </div>
                                                    <div className="flex-50-i">
                                                        <iframe
                                                            width="100%"
                                                            height="215"
                                                            src="https://www.youtube.com/embed/LLyT5-SXvtE"
                                                            title="YouTube video player"
                                                            frameborder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowfullscreen
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sticky-home-wrapper">
                                        <div className="rn-about-area section-height">
                                            <div className="inner">
                                                <h5 className="title">
                                                    Contact Me
                                                </h5>

                                                <p className="about-disc">
                                                    Let’s connect on your event,
                                                    either social or corporate.
                                                    I’m available for freelance
                                                    work as a master of
                                                    ceremony, singer, actor,
                                                    voice artist or educator. 
                                                </p>
                                                <p>
                                                    Email: anujgurwara@gmail.com 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
            <Footer data={{ ...data.footer }} className="section-separator" />
        </Layout>
    );
};

export const query = graphql`
    query PoliticianPageQuery {
        site {
            ...Site
        }
        header: general(section: { eq: "header-1" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-1" }) {
            menu {
                ...Menu01
            }
        }
        footer: general(section: { eq: "footer-1" }) {
            ...Footer01
        }
        homePage(title: { eq: "politician-home" }) {
            content {
                ...Content01
            }
        }
        allArticle(limit: 3) {
            nodes {
                ...Article
            }
        }
    }
`;

IndexPolititcianPage.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
                contact: PropTypes.shape({
                    phone: PropTypes.string,
                    email: PropTypes.string,
                }),
                getform_url: PropTypes.string,
            }),
        }),
        homePage: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        navigation: PropTypes.shape({}),
        header: PropTypes.shape({}),
        footer: PropTypes.shape({}),
    }),
};

export default IndexPolititcianPage;
